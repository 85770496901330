import React, { Fragment } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import { SEO } from "../components/SEO";
import Footer from "../components/Footer";
import { truncateString, removeDateFromString } from "../utils/common";

const Index = ({
  data: { sanityCategory, allSanityArticle, sanitySite },
  params,
}) => {
  //   const slug = params.category;
  const { nodes } = allSanityArticle;

  //   const removeDate = (str) => {
  //     const colonIndex = str.indexOf(":");
  //     if (colonIndex !== -1) {
  //       return str.substring(colonIndex + 1);
  //     }
  //     return str;
  //   };

  return (
    <Fragment>
      <Layout headerData={sanitySite?.header}>
        <section className={"bg-black"}>
          <div className={"container mx-auto py-10"}>
            <h1 className={"gg-text text-5xl min-h-[60px]"}>
              {sanityCategory.title}
            </h1>
            <p className={"font-lora max-w-2xl text-white mt-5"}>
              {sanityCategory.description}
            </p>
          </div>
        </section>

        <section
          className={
            "container mx-auto py-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-7 xl:gap-x-11 gap-y-5 md:gap-y-[35px]"
          }
        >
          {nodes.map((article, i) => {
            return (
              // <Link to={`/articles/${sanityCategory.slug.current}/${article.slug.current}`} key={i}>
              <a
                href={`/${article.slug.current}`}
                key={i}
                className="duration-500 transistion-all hover:bg-slate-100 hover:shadow-xl"
              >
                <div>
                  <div className="rounded-md overflow-hidden sm:h-[200px]">
                    {article?.featuredImage ? (
                      <GatsbyImage
                        className="object-cover w-full h-full rounded-md"
                        image={article?.featuredImage?.asset?.gatsbyImageData}
                        alt={article?.heading}
                      />
                    ) : (
                      <StaticImage
                        src="../../images/placeholder.webp"
                        alt="placeholder"
                        className="object-cover w-full h-full rounded-md"
                      />
                    )}
                  </div>
                  <div className="px-2 py-2">
                    {article?.heading && (
                      <p className={"font-lora font-bold text-lg"}>
                        {removeDateFromString(article.heading)}
                      </p>
                    )}

                    {article?.summary && (
                      <p className="pt-1 text-sm font-normal font-ssp lg:text-base">
                        {truncateString(article.summary, 200)}
                      </p>
                    )}
                  </div>
                </div>
              </a>
            );
          })}
        </section>
      </Layout>
      <Footer data={sanitySite?.footer} />
    </Fragment>
  );
};

export default Index;
export const Head = ({ data: { sanityCategory } }) => (
  <SEO metadata={sanityCategory?.pageMetadata || {}} />
);
export const query = graphql`
  query ($categoryId: String!) {
    sanityCategory(id: { eq: $categoryId }) {
      title
      slug {
        current
      }
      description
      id
      pageMetadata {
        title
        schema
        keywords
        description
        canonical
        image {
          asset {
            url
          }
        }
      }
    }
    allSanityArticle(
      filter: { category: { elemMatch: { id: { eq: $categoryId } } } }
      sort: { order: ASC, fields: order }
    ) {
      nodes {
        templateType
        slug {
          current
        }
        order
        featuredImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
          }
        }
        summary
        id
        heading
      }
    }
    sanitySite {
      header {
        links {
          type
          label
          path
          url
          childMenu {
            type
            label
            path
            url
          }
        }
      }
      footer {
        copyright
        topLinks {
          label
          path
        }
        bottomLinks {
          label
          path
        }
        facebook
        linkedIn
        instagram
        medium
        substack
        youtube
        tiktok
      }
    }
  }
`;
